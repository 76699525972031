<template>

  <transition name="fade">
    <div v-if="allUserNotifications.length > 0" class="user-notifications">

      <div v-for="(userNotification, indexUserNotification) in allUserNotifications"
           :key="indexUserNotification"
           :style="{ top: 'calc(5px + ' + (indexUserNotification * 5) + 'px)',
           left: 'calc(50% + ' + (indexUserNotification * 5) + 'px)',
           'background-color': primary_color + '!important'}"
           class="user-notification">

        <span class="message">{{ userNotification.Message }}</span>

        <button class="btn btn-primary"
                :style="{ color: primary_color + '!important'}"
                @click="deleteUserNotification(userNotification.ID)"
        >OK</button>

      </div>

    </div>
  </transition>

</template>

<script>

import {useStore} from "vuex";
import {computed} from "vue";

export default {

  name: 'UserNotifications',

  setup() {

    const store = useStore();

    const allUserNotifications = computed(() => store.getters["userNotifications/getUserNotifications"]);

    const deleteUserNotification = async (userNotificationID) => {
      await store.dispatch('userNotifications/removeUserNotification', userNotificationID);
    }

    const primary_color = store.state.tenant.tenantinfo.primarycolor

    return {
      allUserNotifications,
      deleteUserNotification,
      primary_color
    }
  }

}

</script>

<style lang="scss" scoped>

.user-notifications {

  .user-notification {
    position: fixed;
    transform: translateX(-50%);
    width: 40%;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 20px 20px 10px;
    -webkit-box-shadow: 3px 3px 10px 3px rgba(0,0,0,0.3);
    box-shadow: 3px 3px 10px 3px rgba(0,0,0,0.3);
    border-radius: 10px;
    color: white;
    min-height: 170px;

    .message {
      font-size: 20px;
      text-align: center;
    }

    .btn {
      padding: 5px 10px;
      font-size: 15px;
      background-color: white !important;
    }

  }

}

</style>
